@import '~ethos-design-system/src/components/Media/Media.scss';

.BannerLogos__Wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;

  @include for-tablet-and-up {
    &.BannerLogosSeparated {
      padding-right: var(--Space-32);
      padding-left: var(--Space-32);
    }
  }

  @include for-phone-only {
    padding: var(--Space-32) 16px;
    max-width: calc(480px + var(--Space-48));
    margin: 0 auto;
  }

  @include for-tablet-and-laptop {
    padding-top: var(--Space-56);
    padding-bottom: var(--Space-56);
  }

  @include for-laptop-only {
    padding-right: var(--Space-64);
    padding-left: var(--Space-64);
  }

  @include for-desktop-only {
    padding: 0 var(--Space-80) var(--Space-8);
  }

  &.ReducedPadding {
    @include for-phone-only {
      padding-top: var(--Space-4);
      padding-bottom: var(--Space-4);
    }

    @include for-tablet-and-laptop {
      padding-top: var(--Space-8);
      padding-bottom: var(--Space-8);
    }
  }

  &.AlternateLinkPadding {
    a {
      padding: 0 var(--Space-8);
    }
  }

  &.darkGray {
    background: var(--GrayPrimary--opaque);
    max-width: none;
  }

  &.green {
    background: #438981;
    max-width: none;
  }

  &.white {
    background: var(--White);
    max-width: none;
  }
}

.BannerLogos__Text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  @include for-phone-only {
    padding-right: var(--Space-24);
    padding-left: var(--Space-24);
  }
  @include for-tablet-only {
    padding-right: var(--Space-48);
    padding-left: var(--Space-48);
  }
  @include for-laptop-and-up {
    padding-bottom: var(--Space-8);
    padding-top: var(--Space-24);
  }
}

.BannerLogos {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  &.BannerLogosCenter {
    justify-content: center;
  }

  &.BannerLogosLeft {
    justify-content: flex-start;
  }

  &.BannerLogosRight {
    justify-content: flex-end;
  }

  &.BannerLogosSeparated {
    justify-content: space-between;
  }

  @include for-phone-only {
    &.BannerLogosSingleRow {
      justify-content: space-between;
      .BannerLogos__Column {
        height: 15px;
      }
    }
    padding-right: 0;
    padding-left: 0;
  }
  @include for-tablet-only {
    padding-right: var(--Space-16);
    padding-left: var(--Space-16);
  }
}

.BannerLogosHasCopy {
  @include for-phone-only {
    padding-top: var(--Space-32);
  }
  @include for-tablet-only {
    padding-top: var(--Space-40);
  }
}

.BannerLogos__Column {
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include for-phone-only {
    padding: 0 var(--Space-4);
    height: var(--Space-48);
  }
  @include for-tablet-and-laptop {
    padding: 0 var(--Space-32);
  }
  @include for-desktop-only {
    padding: 0 var(--Space-40);
  }

  &.ReducedColumnPadding {
    @include for-phone-only {
      padding-left: var(--Space-4);
      padding-right: var(--Space-4);
    }

    @include for-tablet-and-laptop {
      padding-left: var(--Space-8);
      padding-right: var(--Space-8);
    }

    @include for-desktop-only {
      padding-left: var(--Space-8);
      padding-right: var(--Space-8);
    }
  }

  &.ExtraMobilePadding {
    @include for-phone-only {
      padding-left: var(--Space-16);
      padding-right: var(--Space-16);
    }
  }

  a {
    display: block;
  }
  &.ImageMedium {
    height: 96px;
    @include for-phone-only {
      height: var(--Space-56);
    }
  }
  &.ImageLarge {
    height: 112px;
    @include for-phone-only {
      height: 80px;
    }
  }
}

.BannerLogos__Image {
  height: 100%;
  max-height: 32px;
  max-width: 136px;
  margin: 0 auto;
  object-fit: contain !important;
  &.NoMaxWidthHeight {
    max-height: none;
    max-width: none;
  }
  &.ImageMedium {
    max-height: 64px;
    max-width: 272px;
  }
  &.ImageLarge {
    max-height: 96px;
    max-width: 408px;
  }
  @include for-phone-only {
    max-height: 15px;
    max-width: 54px;
    &.ImageMedium {
      max-width: 85px;
      max-height: 40px;
    }
    &.ImageLarge {
      max-width: 70px;
      max-height: 80px;
    }
    &.SingleImage {
      max-width: none;
    }
  }
}
